<template>
  <div id="all-events-list">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          <h3>Change Zone For {{ record.LomName }}</h3>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-form
        ref="form1"
        v-model="valid1"
        lazy-validation
        v-on:submit.prevent="submitForm"
      >
        <v-card-text class="ml-8 mt-10 text-h5">
          <v-row wrap>
            <v-col cols="12" sm="2" md="2" lg="2">Lo Name</v-col>
            <v-col cols="12" sm="7" md="7" lg="7"
              >: &nbsp;{{ record.LomName }}</v-col
            >
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="2" md="2" lg="2">Current Region</v-col>
            <v-col cols="12" sm="7" md="7" lg="7"
              >: &nbsp;{{ record.RegionName }}</v-col
            >
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="2" md="2" lg="2">Current Zone</v-col>
            <v-col cols="12" sm="7" md="7" lg="7"
              >: &nbsp;{{ record.ZoneName }}</v-col
            >
          </v-row>
          <v-row wrap>
            <v-col cols="12" sm="2" md="2" lg="2">New Zone</v-col>
            <v-col cols="12" sm="4" md="4" lg="4">
              <v-autocomplete
                :reduce="(option) => option.value"
                v-model="ZoneCode"
                :rules="ZoneCodeRules"
                :items="ZoneCodeOptions"
                :loading="ZoneCodeOptionsLoading"
                dense
                clearable
                required
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row wrap>
          <v-col align="center" cols="12" md="12">
            <v-btn
              :disabled="!valid1"
              @click.prevent="submitForm"
              :loading="submitFlag"
              color="#8950FC"
              elevation="30"
              shaped
              tile
              large
              class="btn btn-primary font-size-h6 px-10 py-4 my-3 mr-3 white--text"
            >
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
    
    <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    recordData: {
      type: Array,
      required: true,
    },
    Year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      submitFlag: false,
      ProgressLoadingFlag: false,

      Modules: {},
      CurrentYearId: 0,

      record: {},

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,
      ResultFlag: false,
    };
  },
  watch: {
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.LoadingFlag = false;
      this.ZoneCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
      this.getZoneCodeOptions();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.ResultFlag);
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
    },
    getTableRecords() {
      console.log("getTableRecords");
      this.record = this.recordData;
      console.log("records = " + this.record);
      console.log("yearCode =", this.yearCode);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.yearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/zone/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      // this.rows = [];

      if (validate1) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-lom/lom-zone-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        var upload = {
          Year: this.Year,
          Zone: this.record.ZoneId,
          Lom: this.record.LomId,
          NewZone: this.ZoneCode,
        };
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, true);
              thisIns.resetForm();
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            this.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Year should not be empty. ";
        }
        if (!validate2) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
    <style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#all-events-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>